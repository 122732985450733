import CountryTitleBanner from "../components/CountryTitleBanner";
import Footer from "../layout/Footer";

const PrivacyPolicy = () => {
  return(
    <div className="content">
        <CountryTitleBanner />
        <div className="privacy-policy">     
        <h1>Privacy Policy for Travel Jaunt (Website and Android App)</h1>

        <p><strong>Effective Date:</strong> March 1, 2025</p>

        <p>This Privacy Policy describes how traveljaunt.org ("we," "us," or "our") collects, uses, and shares information when you use the Travel Jaunt website and Android application (collectively, the "Services").</p>

        <h2>1. Information We Collect</h2>

        <ul>
            <li><strong>Automatically Collected Information:</strong>
                <ul>
                    <li>Device information (e.g., device type, operating system, unique device identifiers).</li>
                    <li>Usage information (e.g., how you interact with the app and website, pages viewed, time spent).</li>
                    <li>IP address.</li>
                </ul>
            </li>
            <li><strong>User-Submitted Reviews:</strong>
                <ul>
                    <li>Currently, user-submitted reviews are stored anonymously. We do not collect personally identifiable information (PII) associated with these reviews.</li>
                </ul>
            </li>
            <li><strong>Information from APIs:</strong>
                <ul>
                    <li>We utilize a REST API to provide country and location information. This information is publicly available and does not contain personal data from our users.</li>
                </ul>
            </li>
        </ul>

        <h2>2. How We Use Your Information</h2>

        <ul>
            <li><strong>To Provide and Improve the Services:</strong>
                <ul>
                    <li>We use automatically collected information to analyze usage patterns, improve the functionality of our Services, and ensure optimal performance.</li>
                    <li>To provide the country and location data that the app and website provide.</li>
                </ul>
            </li>
            <li><strong>Anonymous Reviews:</strong>
                <ul>
                    <li>Anonymous reviews are used to provide user feedback to other users, and to help improve the service.</li>
                </ul>
            </li>
        </ul>

        <h2>3. Data Sharing</h2>

        <ul>
            <li><strong>Third-Party Service Providers:</strong>
                <ul>
                    <li>We may use third-party service providers to assist us in analyzing usage data. These providers are contractually obligated to protect your information and only use it for the purposes we specify.</li>
                </ul>
            </li>
            <li><strong>Legal Compliance:</strong>
                <ul>
                    <li>We may disclose your information if required by law or in response to a valid legal request.</li>
                </ul>
            </li>
        </ul>

        <h2>4. Data Security</h2>

        <p>We take reasonable measures to protect the information we collect from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.</p>

        <h2>5. Children's Privacy</h2>

        <p>Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete it.</p>

        <h2>6. Future Account Features</h2>

        <p>If we implement user account features in the future, this privacy policy will be updated to reflect the collection and use of any personally identifiable information associated with those accounts. Users will be notified of these changes.</p>

        <h2>7. Changes to This Privacy Policy</h2>

        <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new Privacy Policy on this page.</p>

        <h2>8. Contact Us</h2>

        <p>If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:cbri4nt@gmail.com">cbri4nt@gmail.com</a></p>

        </div>
        <Footer />
    </div>


  );
}

export default PrivacyPolicy;