const Footer = () => {
  return (
      <footer className="footer">
          <a href="/privacypolicy">Privacy Policy</a>
          <hr />
          <p>
              Copyright &copy; { new Date().getFullYear() }, All Rights Reserved
          </p>
      </footer>
  );
}

export default Footer;